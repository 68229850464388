var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center line-height-1 pb-2 "},[_c('i',{staticClass:"text-primary ti ti-users small-gap-right"}),_c('h4',{staticClass:"text-primary mb-0"},[_vm._v(" Daftar Siswa ")])]),_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"1"}},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"options":_vm.options},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('b-col'),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1)],1),_c('vue-good-table',{attrs:{"line-numbers":true,"columns":_vm.columns,"rows":_vm.rows,"search-options":{
              enabled: true,
              externalQuery: _vm.searchTerm,
            },"select-options":{
              // enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: true,
              perPage: _vm.pageLength,
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'fullName')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"text":_vm.avatarText(props.row.fullName),"src":props.row.avatar,"variant":"light-primary"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.fullName))])],1):(props.column.field === 'action')?_c('span',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.kickStudentFromClassroom(props.row.id)}}},[_c('i',{staticClass:"ti ti-trash small-gap-right"}),_vm._v(" Hapus ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"hidden":"","options":_vm.options},on:{"input":function($event){return props.perPageChanged({ currentPerPage: _vm.pageLength })}},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing "+_vm._s(_vm.pageLength <= props.total ? _vm.pageLength * _vm.currentPage : props.total)+" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) {
                        _vm.currentPage = value
                        props.pageChanged({ currentPage: value })
                      }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }