<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div
            class="d-flex justify-content-between align-items-center line-height-1 pb-2 "
          >
            <div class="d-flex align-items-center line-height-1">
              <i class="text-primary ti ti-list-details small-gap-right" />
              <h4 class="text-primary mb-0">
                Akses cepat daftar materi
              </h4>
            </div>

            <div>
              <b-form-checkbox
                class="mt-1"
                name="check-button"
                switch
                inline
                @change="isEditMode = !isEditMode"
              >
                Mode Edit Kelas
              </b-form-checkbox>
            </div>
          </div>

          <!-- Quick Class Description -->
          <b-card
            bg-variant="transparent"
            class="border-light-secondary mb-1"
            :title="classroomContentData.name"
          >
            <b-card-text>
              {{ classroomContentData.description }}
            </b-card-text>

            <!-- Editable Mode -->
            <b-button
              v-show="isEditMode"
              variant="outline-primary"
              @click="triggerModal('classroom-edit-modal')"
            >
              <i class="ti ti-pencil small-gap-right" />
              Edit Deskripsi
            </b-button>
          </b-card>

          <!-- Announcement -->
          <div class="announcement-wrapper">
            <h5 class="font-weight-bolder py-1">
              Pengumuman
            </h5>
            <b-alert
              v-if="classroomContentData.announcements.length > 0"
              show
              variant="warning"
            >
              <div
                v-for="announcement in classroomContentData.announcements"
                :key="announcement.id"
                class="alert-body p-2"
              >
                <div class="d-flex">
                  <div class="mr-1">
                    <b-avatar rounded="lg" class="bg-white">
                      <i class="card-icon text-warning ti ti-bell" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h5
                      class="font-weight-bolder small-gap-bottom text-warning"
                    >
                      {{ announcement.title }}
                    </h5>
                    <span class="font-weight-normal">
                      {{ announcement.description }}
                    </span>
                  </div>
                </div>
              </div>
            </b-alert>

            <b-button
              v-if="classroomContentData.announcements.length === 0"
              class="w-100"
              variant="outline-warning"
              @click="triggerModal('announcement-add-modal')"
            >
              <i class="ti ti-alert-circle small-gap-right" />
              Tambah Pengumuman
            </b-button>
          </div>

          <!-- Editable Mode -->
          <b-button
            v-show="isEditMode && classroomContentData.announcements.length > 0"
            variant="outline-primary"
            @click="triggerModal('announcement-add-modal')"
          >
            <i class="ti ti-pencil small-gap-right" />
            Edit Pengumuman
          </b-button>

          <!-- Material List -->
          <h5 class="font-weight-bolder mt-1 small-gap-bottom">
            Daftar Pertemuan
          </h5>
          <app-collapse type="default">
            <app-collapse-item
              v-for="(content, index) in classroomContentData.class_contents"
              :key="index"
              :title="`Pertemuan Ke-${index + 1} : ${content.title}`"
              :badge-text="index + 1 === 1 ? 'Sedang Berlangsung' : ''"
              :badge-variant="index + 1 === 1 ? 'light-warning' : ''"
              :is-visible="index + 1 === 1"
            >
              <span class="pb-1">
                {{ content.description }}
              </span>

              <b-row class="mt-1">
                <!-- Materi Card -->
                <b-col
                  v-if="content.materials.length > 0"
                  lg="12"
                  class="small-gap-bottom"
                >
                  <b-card
                    bg-variant="transparent"
                    class="material-card border-light-secondary"
                  >
                    <div class="d-flex">
                      <div class="mr-1">
                        <b-avatar rounded="lg" variant="light-success">
                          <i class="card-icon ti ti-book-2 " />
                        </b-avatar>
                      </div>
                      <div class="d-flex flex-column w-100">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex flex-column ">
                            <!-- Dynamic Material Title -->
                            <span class="text-muted">Materi</span>
                            <h6 class="font-weight-bolder small-gap-bottom">
                              {{ content.title }}
                            </h6>
                          </div>
                          <div>
                            <b-button
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              variant="flat-primary"
                              :to="{
                                name: checkAuthorizeRole(
                                  'classroom-detail-material'
                                ),
                                params: {
                                  id: classroomId,
                                  contentId: content.id,
                                },
                              }"
                            >
                              <span class="align-middle mr-1">
                                Lihat Detail
                              </span>
                              <i class="align-middle ti ti-arrow-right" />
                            </b-button>
                          </div>
                        </div>

                        <b-card-text>
                          {{
                            `Berikut merupakan materi yang akan dibahas pada Pertemuan ke-
                        ${index + 1} : `
                          }}
                          <span class="font-weight-bolder">
                            {{ content.title }}
                          </span>
                        </b-card-text>
                      </div>
                    </div>
                  </b-card>
                </b-col>
                <!-- Task Card -->
                <b-col
                  v-if="content.tasks.length > 0"
                  lg="12"
                  class="small-gap-bottom"
                >
                  <b-card
                    bg-variant="transparent"
                    class="material-card border-light-secondary"
                  >
                    <div class="d-flex">
                      <div class="mr-1">
                        <b-avatar rounded="lg" variant="light-info">
                          <i class="card-icon ti ti-clipboard-list " />
                        </b-avatar>
                      </div>
                      <div class="d-flex flex-column w-100">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex flex-column ">
                            <span class="text-muted">Tugas</span>
                            <h6 class="font-weight-bolder small-gap-bottom">
                              {{ content.title }}
                            </h6>
                          </div>
                          <div>
                            <b-button
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              variant="flat-primary"
                              :to="{
                                name: checkAuthorizeRole(
                                  'classroom-detail-task'
                                ),
                                params: {
                                  id: classroomId,
                                  contentId: content.id,
                                },
                              }"
                            >
                              <span class="align-middle mr-1">
                                Lihat Detail
                              </span>
                              <i class="align-middle ti ti-arrow-right" />
                            </b-button>
                          </div>
                        </div>

                        <b-card-text>
                          {{
                            `Berikut merupakan penugasan yang akan dibahas pada Pertemuan ke-
                        ${index + 1} : `
                          }}
                          <span class="font-weight-bolder">
                            {{ content.title }}
                          </span>
                        </b-card-text>
                      </div>
                    </div>
                  </b-card>
                </b-col>
                <!-- Exam Card -->
                <b-col
                  v-if="content.hasExam === true"
                  lg="12"
                  class="small-gap-bottom"
                >
                  <b-card
                    bg-variant="transparent"
                    class="material-card border-light-secondary small-gap-bottom"
                  >
                    <div class="d-flex">
                      <div class="mr-1">
                        <b-avatar rounded="lg" variant="light-danger">
                          <i class="card-icon ti ti-file-text " />
                        </b-avatar>
                      </div>
                      <div class="d-flex flex-column w-100">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex flex-column ">
                            <span class="text-muted">Ujian</span>
                            <h6 class="font-weight-bolder small-gap-bottom">
                              {{ content.title }}
                            </h6>
                          </div>
                          <div>
                            <b-button
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              variant="flat-primary"
                              :to="{
                                name: checkAuthorizeRole(
                                  'classroom-detail-exam'
                                ),
                                params: {
                                  id: classroomId,
                                  contentId: content.id,
                                },
                              }"
                            >
                              <span class="align-middle mr-1">
                                Lihat Detail
                              </span>
                              <i class="align-middle ti ti-arrow-right" />
                            </b-button>
                          </div>
                        </div>

                        <b-card-text>
                          {{
                            `Berikut merupakan ujian yang akan dibahas pada Pertemuan ke-
                        ${index + 1} : `
                          }}
                          <span class="font-weight-bolder">
                            {{ content.title }}
                          </span>
                        </b-card-text>
                      </div>
                    </div>
                  </b-card>
                </b-col>
                <!-- Forum Card -->
                <b-col
                  v-if="content.forums.length > 0"
                  lg="12"
                  class="small-gap-bottom"
                >
                  <b-card
                    bg-variant="transparent"
                    class="material-card border-light-secondary"
                  >
                    <div class="d-flex">
                      <div class="mr-1">
                        <b-avatar rounded="lg" variant="light-warning">
                          <i class="card-icon ti ti-brand-hipchat " />
                        </b-avatar>
                      </div>
                      <div class="d-flex flex-column w-100">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex flex-column ">
                            <span class="text-muted">Forum</span>
                            <h6 class="font-weight-bolder small-gap-bottom">
                              {{ content.title }}
                            </h6>
                          </div>
                          <div>
                            <b-button
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              variant="flat-primary"
                              :to="{
                                name: checkAuthorizeRole(
                                  'classroom-detail-forum'
                                ),
                                params: {
                                  id: classroomId,
                                  contentId: content.id,
                                },
                              }"
                            >
                              <span class="align-middle mr-1">
                                Lihat Detail
                              </span>
                              <i class="align-middle ti ti-arrow-right"></i>
                            </b-button>
                          </div>
                        </div>

                        <b-card-text>
                          {{
                            `Berikut merupakan forum Pertemuan ke-
                        ${index + 1} : `
                          }}
                          <span class="font-weight-bolder">
                            {{ content.title }}
                          </span>
                        </b-card-text>
                      </div>
                    </div>
                  </b-card>
                </b-col>
              </b-row>

              <!-- Created Date -->
              <span class="text-muted">
                Dibuat pada {{ toHumanDate(content.created_at) }}
              </span>

              <!-- Adding Material, Task, and Exam -->
              <b-row class="mt-1">
                <b-col>
                  <b-button
                    variant="success"
                    class="btn-block"
                    :to="{
                      name: checkAuthorizeRole('classroom-detail-material'),
                      params: {
                        id: classroomId,
                        contentId: content.id,
                      },
                    }"
                  >
                    <i class="ti ti-book-2 small-gap-right" />
                    Tambahkan Materi
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    variant="info"
                    class="btn-block"
                    :to="{
                      name: checkAuthorizeRole('classroom-detail-task'),
                      params: {
                        id: classroomId,
                        contentId: content.id,
                      },
                    }"
                  >
                    <i class="ti ti-clipboard-list small-gap-right" />
                    Tambahkan Tugas
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    variant="warning"
                    class="btn-block"
                    :to="{
                      name: checkAuthorizeRole('classroom-detail-forum'),
                      params: {
                        id: classroomId,
                        contentId: content.id,
                      },
                    }"
                  >
                    <i class="ti ti-brand-hipchat small-gap-right" />
                    Tambahkan Forum
                  </b-button>
                </b-col>

                <b-col md="3">
                  <b-button
                    variant="danger"
                    class="btn-block"
                    :to="{
                      name: checkAuthorizeRole('classroom-detail-exam'),
                      params: {
                        id: classroomId,
                        contentId: content.id,
                      },
                    }"
                  >
                    <i class="ti ti-file-text small-gap-right" />
                    Tambahkan Ujian
                  </b-button>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>

          <!-- Editable Mode -->
          <div class="d-flex align-content-stretch">
            <b-button
              v-show="
                isEditMode || classroomContentData.class_contents.length === 0
              "
              class="w-100"
              variant="outline-primary"
              @click="triggerModal('content-add-modal')"
            >
              <i class="ti ti-plus small-gap-right" />
              Tambah Pertemuan
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Add Content Modal -->
    <classroom-content-add
      :content-week="classroomContentData.class_contents.length + 1"
      @refresh-content="refreshContent"
    />

    <!-- Add Announcement Modal -->
    <classroom-announcement-add
      :announcement-data="
        announcementData.length > 0 ? announcementData[0] : {}
      "
      :is-update="isUpdateAnnouncement"
      @refresh-content="refreshContent"
    />

    <!-- Add -->
    <classroom-description-edit :classroom-data="classroomContentData" />
  </div>
</template>

<script>
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText, toHumanDate } from '@core/utils/filter'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BAlert,
  BAvatar,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import ClassroomContentAdd from '../component/ClassroomContentAddModal.vue'
import ClassroomAnnouncementAdd from '../component/ClassroomAnnouncementAddModal.vue'
import ClassroomDescriptionEdit from '../component/ClassroomDescriptionEditModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BAlert,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,

    ClassroomContentAdd,
    ClassroomAnnouncementAdd,
    ClassroomDescriptionEdit,
  },
  directives: {
    Ripple,
  },
  props: {
    classroomContentData: {
      type: Object,
      default: () => ({}),
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    announcementData: {
      type: Array,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      isUpdateAnnouncement: this.announcementData.length > 0,
      classroomId: this.$router.currentRoute.params.id,
    }
  },
  methods: {
    avatarText,
    toHumanDate,
    checkAuthorizeRole,
    triggerModal(modalId) {
      this.$bvModal.show(modalId)
    },
    refreshContent() {
      this.$emit('update-content')
    },
  },
}
</script>

<style lang="scss" scoped>
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>
