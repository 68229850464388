<template>
  <section class="font-times-roman">
    <header class="d-flex border-bottom p-3">
      <img
        width="50px"
        :src="data.school.logo"
        alt=""
      >
      <div class="pl-2">
        <h3 class="mb-0">
          {{ toUppercase(data.school.name) }}
        </h3>
        <span>
          {{ data.school.address }}
        </span>
        <span> | Telp. {{ data.school.phone_number }} </span>
      </div>
    </header>

    <div class="py-2">
      <h3 class="text-center">
        JURNAL MENGAJAR GURU
      </h3>
      <div class="d-flex justify-content-center">
        <vue-qrcode
          :value="`${$route.fullPath}/teacher/classroom/${data.classroom.id}`"
          :options="{ width: 150 }"
        />
        <div class="pl-2">
          <table>
            <tr
              v-for="desc in quickDescriptions"
              :key="desc"
            >
              <td>{{ desc.label }}</td>
              <td class="px-1">
                :
              </td>
              <td>{{ desc.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="px-4 py-1">
      <h6>
        Materi Pembelajaran
      </h6>
      <span>
        {{ data.class_content.title }}
      </span>
      <br>
      <span>
        {{ data.journal.journal }}
      </span>
    </div>

    <div class="px-4 py-1">
      <h6>
        Siswa Hadir
      </h6>
      <span>
        {{
          data.is_present_students.length !== 0
            ? data.is_present_students
              .map((presence) => presence.student.name)
              .join(', ')
            : '-'
        }}
      </span>
    </div>

    <div class="px-4 py-1">
      <h6>
        Siswa Tidak Hadir
      </h6>
      <span>
        {{
          data.is_not_present_students.length !== 0
            ? data.is_not_present_students
              .map((presence) => presence.student.name)
              .join(', ')
            : '-'
        }}
      </span>
    </div>

    <div class="px-4 py-1">
      <h6>
        Rekapitulasi
      </h6>
      <table>
        <tr>
          <td>Jumlah Siswa</td>
          <td class="px-1">
            :
          </td>
          <td>{{ data.total_students }}</td>
        </tr>
        <tr>
          <td>Siswa Hadir</td>
          <td class="px-1">
            :
          </td>
          <td>{{ data.is_present_students.length }}</td>
        </tr>
        <tr>
          <td>Siswa Tidak Hadir</td>
          <td class="px-1">
            :
          </td>
          <td>{{ data.is_not_present_students.length }}</td>
        </tr>
      </table>
    </div>

    <div class="px-4 mb-4">
      <b-row>
        <b-col class="d-flex justify-content-center">
          <table class="empty-cell">
            <tr>
              <td />
            </tr>
            <tr class="mb-4">
              <td class="text-center pt-2">
                Kepala Sekolah
              </td>
            </tr>
            <tr>
              <td class="text-center pt-4">
                {{ journal.grandmaster.name }}
              </td>
            </tr>
            <tr>
              <td class="text-center">
                {{ journal.grandmaster.id }}
              </td>
            </tr>
          </table>
        </b-col>
        <b-col class="d-flex justify-content-center">
          <table class="empty-cell">
            <tr>
              {{
                journal.location
              }},
              {{
                today
              }}
            </tr>
            <tr>
              <td class="text-center">
                Guru
              </td>
            </tr>
            <tr>
              <td class="text-center pt-4">
                {{ data.teacher.name }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>

    <div class="d-flex justify-content-between p-4">
      <span class=""> Dicetak : {{ today }} | {{ todayTime }} WIB </span>
      <span>
        Kelas Jateng
      </span>
    </div>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import VueQrcode from '@chenfengyuan/vue-qrcode'

export default {
  components: {
    BRow,
    BCol,

    VueQrcode,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    journal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      today: new Date().toLocaleDateString('id-ID', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
      todayTime: new Date().toLocaleTimeString('id-ID', {
        hour: '2-digit',
        minute: '2-digit',
      }),
    }
  },

  computed: {
    quickDescriptions() {
      const data = {
        id: {
          label: 'ID',
          value: this.data.journal.id,
        },
        teacherName: {
          label: 'Nama Guru',
          value: this.data.teacher.name,
        },
        classroomName: {
          label: 'Nama Kelas',
          value: this.data.classroom.name,
        },
        material: {
          label: 'Materi',
          value: this.data.class_content.title,
        },
        date: {
          label: 'Tanggal',
          value: this.toLocaleDate(this.data.class_content.start_date),
        },
        methods: {
          label: 'Metode',
          value: 'Pembelajaran Daring',
        },
        media: {
          label: 'Media',
          value: 'Komputer / Laptop / Smartphone',
        },
      }

      return data
    },
  },
  methods: {
    toLocaleDate(date) {
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }

      return new Date(date).toLocaleDateString('id-ID', options)
    },
    toUppercase(str) {
      return str.toUpperCase()
    },
  },
}
</script>

<style scoped>
.font-times-roman {
  font-family: 'Times New Roman', Times, serif !important;
  color: #000 !important;
}

h3,
h6 {
  color: #000 !important;
  font-weight: bolder !important;
}

.empty-cell {
  empty-cells: show;
}
</style>
