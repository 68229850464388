var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center line-height-1 pb-2 "},[_c('i',{staticClass:"text-primary ti ti-star small-gap-right"}),_c('h4',{staticClass:"text-primary mb-0"},[_vm._v("Daftar Nilai")])]),_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"1"}},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"options":_vm.options},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('b-col'),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Filter")]),_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"options":_vm.contentList.map(function (content, index) { return ({
                        text: ("Pertemuan " + (index + 1)),
                        value: content.id,
                      }); })},on:{"change":function($event){return _vm.handleContentChange(_vm.selectedContent)}},model:{value:(_vm.selectedContent),callback:function ($$v) {_vm.selectedContent=$$v},expression:"selectedContent"}}),_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"options":_vm.taskList},on:{"change":function($event){return _vm.handleTaskChange()}},model:{value:(_vm.selectedTask),callback:function ($$v) {_vm.selectedTask=$$v},expression:"selectedTask"}})],1)])],1)],1),(!_vm.isLoading)?_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
                enabled: true,
                externalQuery: _vm.searchTerm,
              },"select-options":{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              },"pagination-options":{
                enabled: true,
                perPage: _vm.pageLength,
              },"line-numbers":true},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'fullName')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"text":_vm.avatarText(props.row.fullName),"src":props.row.avatar,"variant":"light-primary"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.fullName))])],1):(props.column.field === 'grade')?_c('span',[_vm._v(" "+_vm._s(props.row.grade)+" ")]):(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status).variant}},[_vm._v(" "+_vm._s(_vm.statusVariant(props.row.status).text)+" ")])],1):(props.column.field === 'action')?_c('span',[(props.formattedRow['grade'] === 0)?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"variant":props.row.status === 'unavailable'
                        ? 'secondary'
                        : 'primary',"to":{
                      name: _vm.checkAuthorizeRole('classroom-detail-grading'),
                      params: {
                        classroomId: _vm.$route.params.classroomId,
                        contentId: _vm.selectedContent,
                        taskId: _vm.selectedTask,
                        studentId: props.row.id,
                      },
                    },"disabled":props.row.status === 'unavailable'}},[_c('i',{staticClass:"ti ti-star small-gap-right"}),_vm._v(" Nilai ")]):_vm._e(),(props.formattedRow['grade'] !== 0)?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"variant":"warning","to":{
                      name: _vm.checkAuthorizeRole('classroom-detail-grading'),
                      params: {
                        classroomId: _vm.$route.params.classroomId,
                        contentId: _vm.selectedContent,
                        taskId: _vm.selectedTask,
                        studentId: props.row.id,
                      },
                    }}},[_c('i',{staticClass:"ti ti-pencil small-gap-right"}),_vm._v(" Edit Nilai ")]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"hidden":"","options":_vm.options},on:{"input":function($event){return props.perPageChanged({ currentPerPage: _vm.pageLength })}},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing "+_vm._s(_vm.pageLength <= props.total ? _vm.pageLength * _vm.currentPage : props.total)+" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) {
                          _vm.currentPage = value
                          props.pageChanged({ currentPage: value })
                        }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,1482979713)})],1):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center mt-4"},[_c('b-spinner',{staticClass:"mr-1",attrs:{"variant":"primary"}}),_c('p',{staticClass:"mt-1"},[_vm._v(" Memuat Data Nilai ")])],1)]):_vm._e()],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }