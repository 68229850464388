<template>
  <b-card
    class="classroom-header mb-1"
    :img-src="headerData.classroomCover"
    img-top
    alt="cover photo"
    body-class="p-0"
  >
    <!-- profile navbar -->
    <div class="classroom-header-nav">
      <div class="p-2 d-flex align-items-center">
        <b-img
          class="classroom-img"
          :text="avatarText(headerData.classroomTitle)"
          :src="headerData.classroomLogo"
          rounded
          fluid
          alt="classroom photo"
        />
        <div class="w-100 ml-2 d-flex justify-content-between">
          <div>
            <h2>
              {{ headerData.classroomTitle }}
            </h2>
            <div class="d-flex">
              <b-badge class="mr-1" variant="light-info">
                <i class="ti ti-user"></i>
                {{ headerData.teacherName }}
              </b-badge>
            </div>
          </div>

          <div>
            <b-button
              variant="outline-secondary"
              @click="handleCloneClassroom()"
            >
              <i class="ti ti-pencil" />
              Clone Kelas
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!--/ profile navbar -->

    <classroom-clone-modal />
  </b-card>
</template>

<script>
import { BBadge, BCard, BImg, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import client from '@/libs/http/axios-config'
import ClassroomCloneModal from './ClassroomCloneModal'

export default {
  components: {
    BCard,
    BBadge,
    BImg,
    BButton,

    ClassroomCloneModal,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
    classroomContent: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isEditClassroomMode: false,

      classroomId: this.$route.params.id,
    }
  },
  methods: {
    toggleEditClassroomMode(event) {
      this.isEditClassroomMode = event
      this.$emit('isEditModeOn', this.isEditClassroomMode)
    },
    toggleNavtab(tab) {
      this.activeNavtab = tab
      this.$emit('activeNavtab', this.activeNavtab)
    },
    avatarText,
    async cloneClassroom() {
      const data = new FormData()
      data.name = this.classroomContent.name
      data.description = this.classroomContent.description
      data.schedule = this.classroomContent.schedule
      data.logo = this.classroomContent.image
      data.start_date = this.classroomContent.start_date
      data.school_id = this.classroomContent.school_year_id

      await client.post(`/teachers/classrooms/${this.classroomId}/copy`, data)
    },

    handleCloneClassroom() {
      this.$swal({
        title: 'Anda Yakin?',
        text: 'Clone Kelas',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ya, Clone Kelas!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.triggerModal('classroom-clone-modal')
        }
      })
    },

    triggerModal(modalId) {
      this.$bvModal.show(modalId)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-img-top {
  max-height: 200px;
  object-fit: cover;
  // object-position: top;
}

.classroom-header {
  .classroom-img {
    border: 0.357rem solid #fff;
    background-color: #fff;
    border-radius: 0.428rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-top: -75px;
  }
}

.button {
  i {
    margin-right: 0.5rem;
  }
}
</style>
