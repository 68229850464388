var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center line-height-1 pb-2 "},[_c('i',{staticClass:"text-primary ti ti-list-check small-gap-right"}),_c('h4',{staticClass:"text-primary mb-0"},[_vm._v(" Daftar Presensi ")])]),_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"1"}},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"options":_vm.options},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('b-col'),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Filter")]),_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"options":_vm.contentList.map(function (content, index) { return ({
                        text: ("Pertemuan " + (index + 1)),
                        value: content.id,
                      }); })},on:{"change":_vm.handleContentChange},model:{value:(_vm.selectedContent),callback:function ($$v) {_vm.selectedContent=$$v},expression:"selectedContent"}}),_c('b-form-select',{staticClass:"w-auto ",attrs:{"options":[
                      'Semua',
                      'Hadir',
                      'Tidak Hadir',
                      'Izin / Sakit' ]},on:{"change":_vm.filterByPresenceStatus},model:{value:(_vm.studentPresenceStatusFilter),callback:function ($$v) {_vm.studentPresenceStatusFilter=$$v},expression:"studentPresenceStatusFilter"}})],1)])],1)],1),_c('vue-good-table',{attrs:{"line-numbers":true,"columns":_vm.columns,"rows":_vm.rows,"search-options":{
              enabled: true,
              externalQuery: _vm.searchTerm,
            },"select-options":{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: true,
              perPage: _vm.pageLength,
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'fullName')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"text":_vm.avatarText(props.row.fullName),"src":props.row.avatar,"variant":"light-primary"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.fullName))])],1):(props.column.field === 'presence')?_c('span',[_c('b-form-group',[_c('v-select',{attrs:{"options":_vm.presenceOptions},on:{"input":function($event){return _vm.handlePresenceChange(props.row)}},model:{value:(props.row.presence.status),callback:function ($$v) {_vm.$set(props.row.presence, "status", $$v)},expression:"props.row.presence.status"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('b-form-select',{staticClass:"w-auto mr-1",attrs:{"hidden":"","options":_vm.options},on:{"input":function($event){return props.perPageChanged({ currentPerPage: _vm.pageLength })}},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing "+_vm._s(_vm.pageLength <= props.total ? _vm.pageLength * _vm.currentPage : props.total)+" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) {
                        _vm.currentPage = value
                        props.pageChanged({ currentPage: value })
                      }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end mt-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.savePresence()}}},[_vm._v(" Simpan Presensi ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }