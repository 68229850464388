<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-list-check small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Presensi
            </h4>
          </div>

          <!-- Table of Student-->
          <div>
            <b-row>
              <b-col lg="1">
                <b-form-select
                  v-model="pageLength"
                  class="w-auto mr-1"
                  :options="options"
                />
              </b-col>
              <b-col></b-col>
              <b-col lg="6">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <!-- Filter -->
                    <label class="mr-1">Filter</label>

                    <!-- Search Input -->
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block mr-1"
                    />

                    <!-- Filter by Content -->
                    <b-form-select
                      v-model="selectedContent"
                      class="w-auto mr-1"
                      :options="
                        contentList.map((content, index) => ({
                          text: `Pertemuan ${index + 1}`,
                          value: content.id,
                        }))
                      "
                      @change="handleContentChange"
                    />

                    <!-- Filter by Status Presence -->
                    <b-form-select
                      v-model="studentPresenceStatusFilter"
                      class="w-auto "
                      :options="[
                        'Semua',
                        'Hadir',
                        'Tidak Hadir',
                        'Izin / Sakit',
                      ]"
                      @change="filterByPresenceStatus"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- table -->
            <vue-good-table
              :line-numbers="true"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'fullName'"
                  class="text-nowrap"
                >
                  <b-avatar
                    :text="avatarText(props.row.fullName)"
                    :src="props.row.avatar"
                    variant="light-primary"
                    class="mx-1"
                  />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'presence'">
                  <!-- <b-form-radio
                    v-model="props.row.status"
                    :name="`presence-status-${props.row.id}`"
                    plain
                    value="Presence"
                  >
                    Hadir
                  </b-form-radio>
                  <b-form-radio
                    v-model="props.row.status"
                    :name="`presence-status-${props.row.id}`"
                    plain
                    value="Absent"
                  >
                    Tidak Hadir
                  </b-form-radio>
                  <b-form-radio
                    v-model="props.row.status"
                    :name="`presence-status-${props.row.id}`"
                    plain
                    value="Absence"
                  >
                    Izin / Sakit
                  </b-form-radio> -->

                  <b-form-group>
                    <v-select
                      v-model="props.row.presence.status"
                      :options="presenceOptions"
                      @input="handlePresenceChange(props.row)"
                    />
                  </b-form-group>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="pageLength"
                      hidden
                      class="w-auto mr-1"
                      :options="options"
                      @input="
                        props.perPageChanged({ currentPerPage: pageLength })
                      "
                    />
                    <span class="text-nowrap ">
                      Showing
                      {{
                        pageLength <= props.total
                          ? pageLength * currentPage
                          : props.total
                      }}
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          currentPage = value
                          props.pageChanged({ currentPage: value })
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>

          <div class="d-flex justify-content-end mt-1">
            <b-button variant="primary" @click="savePresence()">
              Simpan Presensi
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// *Utils
import client from '@/libs/http/axios-config'
import { pascalCase, avatarText } from '@core/utils/filter'

// *Components
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadio,

    VueGoodTable,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    contentList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // State
      isLoading: false,
      isUpdate: false,

      // Initial Data
      classroomId: this.$router.currentRoute.params.id,

      // Filter
      selectedContent: this.contentList[0]?.id,
      studentPresenceStatusFilter: 'Semua',
      presenceOptions: [
        { value: 'Present', label: 'Hadir' },
        { value: 'Absent', label: 'Tidak Hadir' },
        { value: 'Absence', label: 'Izin / Sakit' },
      ],

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 10,
      options: ['10', '20', '30'],
      columns: [
        {
          label: 'Nama Siswa',
          field: 'fullName',
        },
        {
          label: 'Kehadiran',
          field: 'presence',
          width: '15%',
        },
      ],
      rows: [],
      listOfStudents: [],
      filteredStudents: [],
    }
  },
  async mounted() {
    await client
      .get(`/teachers/classrooms/${this.classroomId}/students`)
      .then((response) => {
        const students = response.data.data

        const listOfStudents = students.map((student) => {
          return {
            id: student.id,
            avatar: student.avatar,
            fullName: pascalCase(student.name),
            presence: {
              status: {
                value: 'Absent',
                label: 'Tidak Hadir',
              },
              note: '-',
            },
          }
        })

        this.listOfStudents = listOfStudents
      })
      .catch((error) => {
        console.error(error)
      })

    this.handleContentChange()
  },
  methods: {
    pascalCase,
    avatarText,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    handlePresenceChange(row) {
      const student = this.listOfStudents.find(
        (student) => student.id === row.id
      )

      student.presence.status = row.presence.status
      student.presence.note = row.presence.note
    },
    convertToLabel(value) {
      const label = this.presenceOptions.find(
        (option) => option.value === value
      )

      return label.label
    },
    async handleContentChange() {
      console.log('content changed')
      await client
        .get(
          `/teachers/classrooms/${this.classroomId}/presences?class_content=${this.selectedContent}`
        )
        .then((response) => {
          const presences = response.data.data.student_prensences

          // Determine presence API is Create or Update presence
          if (presences.length > 0) {
            this.isUpdate = true
          } else {
            this.isUpdate = false
          }

          this.listOfStudents.map((student) => {
            const presence = presences.find(
              (presence) => presence.student_id === student.id
            )

            if (presence) {
              student.presence.status.value = presence.is_present
              student.presence.status.label = this.convertToLabel(
                presence.is_present
              )
              student.presence.note = presence.feedback
            } else {
              student.presence.status.value = 'Present'
              student.presence.status.label = 'Hadir'
              student.presence.note = '-'
            }
          })

          this.rows = this.listOfStudents
        })
        .catch((error) => {
          console.error(error)
        })
    },

    async savePresence() {
      const presenceData = this.listOfStudents.map((student) => {
        return {
          student_id: student.id,
          is_present: student.presence.status.value,
          feedback: student.presence.note,
        }
      })

      const formData = {
        data: presenceData,
      }

      if (this.isUpdate) {
        await client
          .put(
            `/teachers/classrooms/${this.classroomId}/presences?class_content=${this.selectedContent}`,
            formData
          )
          .then((response) => {
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Presensi berhasil disimpan'
            )
          })
      } else {
        await client
          .post(
            `/teachers/classrooms/${this.classroomId}/presences?class_content=${this.selectedContent}`,
            formData
          )
          .then((response) => {
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Presensi berhasil disimpan'
            )
          })
      }
    },

    filterByPresenceStatus() {
      this.filteredStudents = this.listOfStudents.filter((student) => {
        if (this.studentPresenceStatusFilter === 'Semua') {
          return true
        }

        const filter = this.presenceOptions.find(
          (option) => option.label === this.studentPresenceStatusFilter
        )
        return student.presence.status.value === filter.value
      })

      this.rows = this.filteredStudents
    },
  },
}
</script>

<style lang="scss">
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}
.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}

.vs__clear {
  display: none;
}
</style>
