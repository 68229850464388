<template>
  <!-- Content Add Modal -->
  <b-modal centered id="classroom-journal-modal" title="Download Jurnal">
    <div>
      <b-form-group label="Nama Kepala Sekolah">
        <b-form-input v-model="journal.grandmaster.name" required />
      </b-form-group>
      <b-form-group label="NIP Kepala Sekolah">
        <b-form-input v-model="journal.grandmaster.id" required />
      </b-form-group>
      <b-form-group label="Kabupaten / Kota">
        <b-form-input v-model="journal.location" required />
      </b-form-group>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="w-100"
        variant="primary"
        @click="handleSubmit"
      >
        <i v-show="!isLoading" class="font-medium-2 ti ti-download" />
        <b-spinner v-show="isLoading" small variant="light" />
        Download Jurnal
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'

import client from '@/libs/http/axios-config'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText, pascalCase } from '@core/utils/filter'
import { emit } from 'process'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      isLoading: false,
      classroomId: this.$router.currentRoute.params.id,
      journal: {
        grandmaster: {
          id: '',
          name: '',
        },
        location: '',
      },
    }
  },

  async mounted() {
    if (localStorage.getItem('journal_print_data')) {
      this.journal = JSON.parse(localStorage.getItem('journal_print_data'))
    }
  },

  methods: {
    pascalCase,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    handleSubmit() {
      // store into local storage
      localStorage.setItem('journal_print_data', JSON.stringify(this.journal))

      this.$emit('name', 'Raja Tamil')
      this.$bvModal.hide('classroom-journal-modal')
    },
  },
}
</script>

<style lang="scss">
#classroom-journal-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}

.pointer {
  cursor: pointer;
}
</style>
