<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-users small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Siswa
            </h4>
          </div>

          <!-- Table of Student-->
          <div>
            <b-row>
              <b-col lg="1">
                <b-form-select
                  v-model="pageLength"
                  class="w-auto mr-1"
                  :options="options"
                />
              </b-col>
              <b-col></b-col>
              <b-col lg="4">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- table -->
            <vue-good-table
              :line-numbers="true"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                // enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'fullName'"
                  class="text-nowrap"
                >
                  <b-avatar
                    :text="avatarText(props.row.fullName)"
                    :src="props.row.avatar"
                    variant="light-primary"
                    class="mx-1"
                  />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  class="d-flex justify-content-center"
                >
                  <b-button
                    variant="outline-danger"
                    class="d-flex align-items-center"
                    @click="kickStudentFromClassroom(props.row.id)"
                  >
                    <i class="ti ti-trash small-gap-right" />
                    Hapus
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="pageLength"
                      hidden
                      class="w-auto mr-1"
                      :options="options"
                      @input="
                        props.perPageChanged({ currentPerPage: pageLength })
                      "
                    />
                    <span class="text-nowrap ">
                      Showing
                      {{
                        pageLength <= props.total
                          ? pageLength * currentPage
                          : props.total
                      }}
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          currentPage = value
                          props.pageChanged({ currentPage: value })
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// *Component
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// *Utils
import { pascalCase, avatarText } from '@core/utils/filter'
import client from '@/libs/http/axios-config'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      classroomId: this.$router.currentRoute.params.id,
      currentPage: 1,
      pageLength: 30,
      options: ['10', '20', '30'],
      columns: [
        {
          label: 'Nama Siswa',
          field: 'fullName',
        },
        {
          label: 'Aksi',
          field: 'action',
          width: '200px',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  async mounted() {
    await client
      .get(`/teachers/classrooms/${this.classroomId}/students`)
      .then((response) => {
        const students = response.data.data

        const listOfStudents = students.map((student) => {
          return {
            id: student.id,
            avatar: student.avatar,
            fullName: pascalCase(student.name),
          }
        })

        this.rows = listOfStudents
      })
      .catch((error) => {
        console.error(error)
      })
  },
  methods: {
    pascalCase,
    avatarText,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    kickStudentFromClassroom(studentId) {
      console.log(studentId)
      this.$swal({
        title: 'Anda Yakin?',
        text: 'Data siswa yang dikeluarkan akan hilang',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteStudent(studentId)
        }
      })
    },

    async deleteStudent(studentId) {
      await client
        .post(
          `/teachers/classrooms/${this.classroomId}/students/${studentId}/remove`
        )
        .then((response) => {
          this.refreshStudents()

          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Siswa berhasil dihapus'
          )
        })
    },

    async refreshStudents() {
      await client
        .get(`/teachers/classrooms/${this.classroomId}/students`)
        .then((response) => {
          const students = response.data.data

          const listOfStudents = students.map((student) => {
            return {
              id: student.id,
              avatar: student.avatar,
              fullName: pascalCase(student.name),
            }
          })

          this.rows = listOfStudents
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>
