<template>
  <!-- Content Add Modal -->
  <b-modal id="classroom-edit-modal" title="Edit Kelas" centered>
    <div>
      <b-form-input v-model="classroomId" hidden />
      <b-form-group label="Nama Kelas">
        <b-form-input v-model="classroomContent.data.title" required />
      </b-form-group>
      <b-form-group label="Deskripsi Kelas">
        <b-form-textarea
          v-model="classroomContent.data.description"
          required
          rows="3"
          no-resize
        />
      </b-form-group>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="w-100"
        variant="primary"
        @click="handleSubmit"
      >
        <i v-show="!isLoading" class="font-medium-2 ti ti-circle-plus" />
        <b-spinner v-show="isLoading" small variant="light" />
        Simpan Kelas
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'

import client from '@/libs/http/axios-config'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    classroomData: {
      type: Object,
      default: () => {
        return {
          title: '',
          description: '',
        }
      },
    },
  },
  mounted() {
    this.classroomContent.data.title = this.classroomData.name
    this.classroomContent.data.description = this.classroomData.description
  },
  data() {
    return {
      isLoading: false,
      classroomId: this.$router.currentRoute.params.id,
      classroomContent: {
        data: {
          title: '',
          description: '',
        },
        errors: {
          title: { state: null, message: '' },
          description: { state: null, message: '' },
        },
      },
    }
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    resetModal() {
      this.classroomContent.data.title = ''
      this.classroomContent.data.description = ''
    },
    formValidation() {
      this.isLoading = true
      let isValid = true

      return isValid
    },
    async handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.formValidation()) {
        // wrap into form data
        const data = new FormData()
        data.append('name', this.classroomContent.data.title)
        data.append('description', this.classroomContent.data.description)
        data.append('_method', 'PUT')

        await client
          .post(`/teachers/classrooms/${this.classroomId}/update`, data)
          .then((response) => {
            this.isLoading = false

            // reset modal content value
            this.resetModal()

            // reload classroom content
            this.$router
              .go({
                name: checkAuthorizeRole('clasroom-detail'),
                params: { id: this.classroomId },
              })
              .then(() => {
                // show success toast
                this.showToast(
                  'success',
                  'CheckIcon',
                  'Berhasil',
                  'Pengumuman berhasil dibuat'
                )
              })
          })
      }
    },
  },
}
</script>

<style lang="scss">
#classroom-edit-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
