<template>
  <!-- Content Add Modal -->
  <b-modal centered id="content-add-modal" title="Tambah Pertemuan">
    <div>
      <b-form-input v-model="classroomId" hidden />
      <b-form-input v-model="contentWeek" hidden />
      <b-form-group
        label="Judul Pertemuan"
        :invalid-feedback="classroomContent.errors.title.message"
      >
        <b-form-input
          v-model="classroomContent.data.title"
          required
          :state="classroomContent.errors.title.state"
        />
      </b-form-group>
      <b-form-group
        label="Tanggal Mulai Pertemuan"
        :invalid-feedback="classroomContent.errors.startDate.message"
      >
        <flat-pickr
          v-model="classroomContent.data.startDate"
          :class="
            classroomContent.errors.startDate.state != null &&
            !classroomContent.errors.startDate.state
              ? 'is-invalid'
              : ''
          "
          class="form-control"
          :config="{
            altInput: true,
            altFormat: 'j F Y',
            dateFormat: 'Y-m-d',
            minDate: 'today',
          }"
        />
      </b-form-group>
      <b-form-group
        label="Deskripsi Pertemuan"
        :invalid-feedback="classroomContent.errors.description.message"
      >
        <b-form-textarea
          v-model="classroomContent.data.description"
          required
          rows="3"
          no-resize
          :state="classroomContent.errors.description.state"
        />
      </b-form-group>
      <b-button
        class="w-100"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        @click="handleSubmit"
      >
        <i v-show="!isLoading" class="font-medium-2 ti ti-circle-plus" />
        <b-spinner v-show="isLoading" small variant="light"></b-spinner>
        Buat Pertemuan
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import {
  BModal,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BSpinner,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    contentWeek: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      classroomId: this.$router.currentRoute.params.id,
      classroomContent: {
        data: {
          title: '',
          description: '',
          startDate: '',
        },
        errors: {
          title: { state: null, message: '' },
          description: { state: null, message: '' },
          startDate: { state: null, message: '' },
        },
      },
    }
  },
  mounted() {
    this.classroomContent.data.startDate = new Date().toISOString().slice(0, 10)
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    resetModal() {
      this.classroomContent.data.title = ''
      this.classroomContent.data.description = ''
      this.classroomContent.data.startDate = ''

      this.classroomContent.errors.title.state = null
      this.classroomContent.errors.description.state = null
      this.classroomContent.errors.startDate.state = null
    },
    formValidation() {
      this.isLoading = true
      let isValid = true

      if (this.classroomContent.data.title === '') {
        this.classroomContent.errors.title.state = false
        this.classroomContent.errors.title.message =
          'Judul Pertemuan Tidak Boleh Kosong'
        isValid = false
      }
      if (this.classroomContent.data.description === '') {
        this.classroomContent.errors.description.state = false
        this.classroomContent.errors.description.message =
          'Deskripsi Pertemuan Tidak Boleh Kosong'
        isValid = false
      }
      if (this.classroomContent.data.startDate === '') {
        this.classroomContent.errors.startDate.state = false
        this.classroomContent.errors.startDate.message =
          'Tanggal Mulai Pertemuan Tidak Boleh Kosong'
        isValid = false
      }
      return isValid
    },
    async handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.formValidation()) {
        // base configuration
        const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
        const config = {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }

        // wrap into form data
        const data = new FormData()
        data.append('title', this.classroomContent.data.title)
        data.append('description', this.classroomContent.data.description)
        data.append('content', this.classroomContent.data.description)
        data.append('start_date', this.classroomContent.data.startDate)
        data.append('week', this.contentWeek)

        // create classroom content
        await axios
          .post(
            `${baseUrl}/classrooms/${this.classroomId}/class-contents`,
            data,
            config
          )
          .then((response) => {
            // set loading
            this.isLoading = false

            // reset modal content value
            this.resetModal()

            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Pertemuan berhasil dibuat'
            )

            // close modal
            this.$bvModal.hide('content-add-modal')

            // update content
            this.$emit('refresh-content')
          })
          .catch((error) => {
            console.error(error)
            this.isLoading = false

            // show error toast
            this.showToast('danger', 'XIcon', 'Gagal', 'Pertemuan gagal dibuat')
          })
      }
    },
  },
}
</script>

<style lang="scss">
#content-add-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
