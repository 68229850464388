<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-file-text small-gap-right" />
            <h4 class="text-primary mb-0">
              Jurnal Kelas
            </h4>
          </div>

          <!-- Table of Student-->
          <div>
            <b-row>
              <b-col lg="1">
                <b-form-select
                  v-model="pageLength"
                  class="w-auto mr-1"
                  :options="options"
                />
              </b-col>
              <b-col></b-col>
              <b-col class="d-flex justify-content-end" lg="4">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block mr-1"
                    />
                  </div>
                </b-form-group>

                <div>
                  <!-- Export Button -->
                  <b-button
                    variant="warning"
                    class="d-flex"
                    @click="handleExport()"
                  >
                    <i
                      v-if="!isButtonExportLoading"
                      class="ti ti-file-export small-gap-right"
                    />
                    <b-spinner
                      class="small-gap-right"
                      small
                      v-if="isButtonExportLoading"
                    />
                    Export
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <!-- table -->
            <vue-good-table
              :line-numbers="true"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'week', type: 'asc' },
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'attendance'"
                  class="text-nowrap"
                >
                  <ul class="pl-0 no-list">
                    <li>Hadir : {{ props.row.attendance.presence }}</li>
                  </ul>
                </span>

                <!-- Column: Name -->
                <span
                  v-else-if="props.column.field === 'description'"
                  class="text-nowrap"
                >
                  <b-form-textarea
                    v-model="props.row.description"
                    placeholder="Detail Kegiatan Pembelajaran"
                    rows="3"
                    no-resize
                  />
                </span>

                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  class="d-flex"
                >
                  <b-button
                    class="d-flex align-items-center"
                    variant="info"
                    @click="generatePDF(props.row.id)"
                  >
                    <i class="ti ti-download small-gap-right" />
                    Download
                  </b-button>

                  <b-button
                    class="d-flex align-items-center small-gap-left"
                    variant="success"
                    @click="saveClassroomJournal(props.row)"
                  >
                    <i class="ti ti-device-floppy small-gap-right" />
                    Simpan
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="pageLength"
                      hidden
                      class="w-auto mr-1"
                      :options="options"
                      @input="
                        props.perPageChanged({ currentPerPage: pageLength })
                      "
                    />
                    <span class="text-nowrap ">
                      Showing
                      {{
                        pageLength <= props.total
                          ? pageLength * currentPage
                          : props.total
                      }}
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          currentPage = value
                          props.pageChanged({ currentPage: value })
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <vue-html2pdf
      :show-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="jurnal-kelas"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      :html2canvas="{
        scale: 1,
        useCORS: true,
      }"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <classroom-journal-file
          :data="exportData"
          :journal="journalExtendData"
        />
      </section>
    </vue-html2pdf>

    <classroom-journal-modal @name="startGenerate" />
  </div>
</template>

<script>
// *Components
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ClassroomJournalFile from '@/views/apps/teacher/classroom/classroom-detail/component/ClassroomJournalFile.vue'
import ClassroomJournalModal from '@/views/apps/teacher/classroom/classroom-detail/component/ClassroomJournalModal.vue'
import VueHtml2pdf from 'vue-html2pdf'

// *Utils
import { pascalCase } from '@core/utils/filter'
import client from '@/libs/http/axios-config'
import { StarIcon } from 'vue-feather-icons'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormTextarea,

    VueGoodTable,
    VueHtml2pdf,
    ClassroomJournalFile,
    ClassroomJournalModal,
  },
  directives: {
    Ripple,
  },
  props: {
    classroomContentData: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      // State
      isButtonExportLoading: false,

      // Data
      classroomId: this.$router.currentRoute.params.id,

      // Table
      currentPage: 1,
      pageLength: 30,
      options: ['5', '10', '30'],
      columns: [
        {
          label: 'Pertemuan',
          field: 'week',
        },
        {
          label: 'Tanggal',
          field: 'startdate',
        },
        {
          label: 'Materi Pokok',
          field: 'title',
        },
        {
          label: 'Kegiatan Pembelajaran',
          field: 'description',
        },
        {
          label: 'Kehadiran',
          field: 'attendance',
        },
        {
          label: 'Aksi',
          field: 'action',
          width: '15%',
        },
      ],
      rows: [],
      searchTerm: '',

      // Export
      exportData: {},
      journalExtendData: {},
    }
  },
  async mounted() {
    await client
      .get(`/teachers/classrooms/${this.classroomId}/journal`)
      .then((response) => {
        const journals = response.data.data.journal

        const listOfJournal = journals.map((journal, index) => {
          const attendance = {
            presence: 0,
            absent: 0,
            absence: 0,
          }

          journal.student_prensences.map((presence) => {
            if (presence.is_present === 'Present') {
              attendance.presence += 1
            } else if (presence.present === 'Absence') {
              attendance.absence += 1
            } else {
              attendance.absent += 1
            }
          })

          return {
            id: journal.classroom_journals[0].id,
            contentId: journal.id,
            week: `Pertemuan ${journal.week}`,
            startdate: new Date(journal.created_at).toLocaleDateString(),
            title: journal.title,
            description: journal.classroom_journals[0].journal,
            attendance: {
              presence:
                attendance.presence === 0
                  ? 'Tidak ada'
                  : `${attendance.presence} Siswa`,
            },
          }
        })

        this.rows = listOfJournal
      })
  },
  methods: {
    pascalCase,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    onProgress(event) {
      console.log(`Processed: ${event} / 100`)
    },
    async generatePDF(journalId) {
      await client
        .get(`/teachers/classrooms/${this.classroomId}/journal/${journalId}`)
        .then((response) => {
          this.exportData = response.data.data
        })

      this.$bvModal.show('classroom-journal-modal')

      // this.$refs.html2Pdf.generatePdf()
    },
    startGenerate() {
      this.journalExtendData = JSON.parse(
        localStorage.getItem('journal_print_data')
      )
      this.$refs.html2Pdf.generatePdf()
    },

    async saveClassroomJournal(journalData) {
      const data = new FormData()
      data.append('journal', journalData.description)
      data.append('class_content_id', journalData.contentId)
      data.append('_method', 'PUT')

      await client
        .post(
          `/teachers/classrooms/${this.classroomId}/journal/${journalData.id}`,
          data
        )
        .then((response) => {
          this.showToast(
            'success',
            'CheckCircleIcon',
            'Berhasil',
            'Berhasil menambahkan jurnal kelas'
          )
        })
    },
    async handleExport() {
      this.isButtonExportLoading = true
      await client
        .get(`/teachers/classrooms/${this.classroomId}/download-journal`, {
          responseType: 'blob',
        })
        .then((response) => {
          this.isButtonExportLoading = false

          const blob = new Blob([response.data], { type: 'application/xlsx' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'Jurnal Kelas.xlsx'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((error) => {
          this.isButtonExportLoading = false
          this.showToast('error', 'XIcon', 'Gagal', error.response.data.message)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.no-list {
  list-style: none;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>
