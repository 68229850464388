<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-star small-gap-right" />
            <h4 class="text-primary mb-0">Daftar Nilai</h4>
          </div>

          <!-- Table of Student-->
          <div>
            <b-row>
              <b-col lg="1">
                <b-form-select
                  v-model="pageLength"
                  class="w-auto mr-1"
                  :options="options"
                />
              </b-col>
              <b-col></b-col>
              <b-col lg="6">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Filter</label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block mr-1"
                    />

                    <!-- Filter by Content -->
                    <b-form-select
                      v-model="selectedContent"
                      class="w-auto mr-1"
                      :options="
                        contentList.map((content, index) => ({
                          text: `Pertemuan ${index + 1}`,
                          value: content.id,
                        }))
                      "
                      @change="handleContentChange(selectedContent)"
                    />

                    <!-- Filter by selected task -->
                    <b-form-select
                      v-model="selectedTask"
                      class="w-auto mr-1"
                      :options="taskList"
                      @change="handleTaskChange()"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- table -->
            <div v-if="!isLoading">
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                }"
                :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: pageLength,
                }"
                :line-numbers="true"
              >
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Name -->
                  <span
                    v-if="props.column.field === 'fullName'"
                    class="text-nowrap"
                  >
                    <b-avatar
                      :text="avatarText(props.row.fullName)"
                      :src="props.row.avatar"
                      variant="light-primary"
                      class="mx-1"
                    />
                    <span class="text-nowrap">{{ props.row.fullName }}</span>
                  </span>

                  <!-- Column: Grade -->
                  <span v-else-if="props.column.field === 'grade'">
                    {{ props.row.grade }}
                  </span>

                  <!-- Column: Status -->
                  <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status).variant">
                      {{ statusVariant(props.row.status).text }}
                    </b-badge>
                  </span>

                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      v-if="props.formattedRow['grade'] === 0"
                      :variant="
                        props.row.status === 'unavailable'
                          ? 'secondary'
                          : 'primary'
                      "
                      class="d-flex align-items-center justify-content-center"
                      :to="{
                        name: checkAuthorizeRole('classroom-detail-grading'),
                        params: {
                          classroomId: $route.params.classroomId,
                          contentId: selectedContent,
                          taskId: selectedTask,
                          studentId: props.row.id,
                        },
                      }"
                      :disabled="props.row.status === 'unavailable'"
                    >
                      <i class="ti ti-star small-gap-right" />
                      Nilai
                    </b-button>
                    <b-button
                      v-if="props.formattedRow['grade'] !== 0"
                      variant="warning"
                      class="d-flex align-items-center justify-content-center"
                      :to="{
                        name: checkAuthorizeRole('classroom-detail-grading'),
                        params: {
                          classroomId: $route.params.classroomId,
                          contentId: selectedContent,
                          taskId: selectedTask,
                          studentId: props.row.id,
                        },
                      }"
                    >
                      <i class="ti ti-pencil small-gap-right" />
                      Edit Nilai
                    </b-button>
                  </span>

                  <!-- Column: Default -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <b-form-select
                        v-model="pageLength"
                        hidden
                        class="w-auto mr-1"
                        :options="options"
                        @input="
                          props.perPageChanged({ currentPerPage: pageLength })
                        "
                      />
                      <span class="text-nowrap ">
                        Showing
                        {{
                          pageLength <= props.total
                            ? pageLength * currentPage
                            : props.total
                        }}
                        of {{ props.total }} entries
                      </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="
                          (value) => {
                            currentPage = value
                            props.pageChanged({ currentPage: value })
                          }
                        "
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </div>

            <!-- Loading State -->
            <div v-if="isLoading" class="d-flex justify-content-center">
              <div class="text-center mt-4">
                <b-spinner variant="primary" class="mr-1" />
                <p class="mt-1">
                  Memuat Data Nilai
                </p>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// *Utils
import { pascalCase, avatarText } from '@core/utils/filter'
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'

// *Components
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,

    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    contentList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: true,
      isEditGrade: false,

      // Initial Data
      classroomId: this.$router.currentRoute.params.id,

      // Filter
      selectedContent: this.contentList[0]?.id,
      selectedContentIndex: 0,
      selectedTask: this.contentList[0].tasks[0]?.id,

      taskList: this.contentList[0]?.tasks.map((task) => ({
        value: task.id,
        text: task.name,
      })),
      studentPresenceStatusFilter: 'Semua',

      // Data
      listOfStudents: [],
      studentTaskData: {},

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 5,
      options: ['5', '10', '30'],
      columns: [
        {
          label: 'Nama Siswa',
          field: 'fullName',
        },
        {
          label: 'Nilai',
          field: 'grade',
          width: '15%',
        },
        {
          label: 'Status',
          field: 'status',
          width: '15%',
        },
        {
          label: 'Keterangan',
          field: 'feedback',
          width: '25%',
        },
        {
          label: 'Aksi',
          field: 'action',
          width: '15%',
        },
      ],
      rows: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        success: {
          text: 'Lulus',
          variant: 'light-success',
        },
        failed: {
          text: 'Gagal',
          variant: 'light-danger',
        },
        standard: {
          text: 'KKM',
          variant: 'light-warning',
        },
        ungraded: {
          text: 'Belum Dinilai',
          variant: 'light-info',
        },
        unavailable: {
          text: 'Belum Mengumpulkan',
          variant: 'light-secondary',
        },
      }

      return (status) => statusColor[status]
    },
  },
  async mounted() {
    // Get students list
    await client
      .get(`/teachers/classrooms/${this.classroomId}/students`)
      .then((response) => {
        const students = response.data.data

        const listOfStudents = students.map((student) => {
          return {
            id: student.id,
            avatar: student.avatar,
            fullName: pascalCase(student.name),
            grade: 0,
            status: 'unavailable',
            feedback: '-',
          }
        })

        this.listOfStudents = listOfStudents
      })
      .catch((error) => {
        console.error(error)
      })

    this.getGradesList()
  },
  methods: {
    pascalCase,
    avatarText,
    checkAuthorizeRole,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    // Get grades list and map on student list
    async getGradesList() {
      // check if content list is not empty
      if (this.contentList.length > 0) {
        console.log('content changed')
        this.isLoading = true

        // check if in the content have tasks
        if (this.selectedTask !== null) {
          await client
            .get(
              `teachers/classrooms/${this.classroomId}/grades?task_id=${this.selectedTask}&class_content_id=${this.selectedContent}`
            )
            .then((response) => {
              const grades = response.data.data

              if (grades.length > 0) {
                this.listOfStudents.map((student) => {
                  this.isLoading = false
                  const studentGrade = grades.find(
                    (grade) => grade.student_id === student.id
                  )

                  if (studentGrade) {
                    if (studentGrade.type === 'submission') {
                      console.log('this is submission type')
                      if (studentGrade.score == 0) {
                        student.grade = studentGrade.score
                        student.status = 'ungraded'
                      } else {
                        student.grade = studentGrade.score
                        student.status = 'success'
                        student.feedback = studentGrade.feedback
                      }
                    } else {
                      console.log('this is exam type')
                      if (studentGrade.score == 0) {
                        student.grade = studentGrade.score
                        student.status = 'ungraded'
                      } else {
                        student.grade = studentGrade.score
                        student.status = 'success'
                        student.feedback = studentGrade.feedback
                      }
                    }
                  } else {
                    student.grade = 0
                    student.status = 'unavailable'
                    student.feedback = '-'
                  }
                })
              } else {
                this.listOfStudents.map((student) => {
                  this.isLoading = false
                  student.grade = 0
                  student.status = 'unavailable'
                  student.feedback = '-'
                })
              }

              const studentsGrades = this.listOfStudents
              this.rows = studentsGrades
            })
            .catch((error) => {
              this.isLoading = false

              console.error(error)
            })
        } else {
          this.isLoading = false
          this.rows = []
        }
      }
    },

    // Handle filter by content id
    async handleContentChange(contentId) {
      const taskList = this.contentList.find(
        (content) => content.id === contentId
      ).tasks

      if (taskList.length > 0) {
        this.taskList = taskList.map((task) => ({
          text: task.name,
          value: task.id,
        }))

        this.selectedTask = taskList[0].id
      } else {
        this.taskList = [
          {
            text: 'Tidak ada tugas',
            value: null,
          },
        ]

        this.selectedTask = this.taskList[0].value
      }

      this.getGradesList()
    },

    // Handle filter by task id
    handleTaskChange() {
      this.getGradesList()
    },

    // Handle show modal detail student task
    async handleGrade(studentId) {
      if (this.selectedTask == null) {
        this.studentTaskData = []
      } else {
        await client
          .get(
            `teachers/classrooms/${this.classroomId}/grades/show?task_id=${this.selectedTask}&class_content_id=${this.selectedContent}&student_id=${studentId}`
          )
          .then((response) => {
            const studentTaskData = response.data.data

            this.studentTaskData = studentTaskData

            this.$bvModal.show('modal-detail-student-task')
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}
.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>
