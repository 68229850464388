<template>
  <!-- Content Add Modal -->
  <b-modal centered id="classroom-clone-modal" title="Clone Kelas">
    <div>
      <b-form-group label="Nama Kelas">
        <b-form-input v-model="classroomContent.data.title" required />
      </b-form-group>
      <b-form-group label="Deskripsi Kelas">
        <b-form-textarea
          v-model="classroomContent.data.description"
          required
          rows="3"
          no-resize
        />
      </b-form-group>
      <b-form-group label="Jadwal Kelas">
        <v-select
          v-model="classroomContent.data.schedule"
          label="title"
          :options="schedules"
        />
      </b-form-group>
      <b-form-group label="Tahun Ajaran">
        <v-select
          v-model="classroomContent.data.school_year_id"
          label="title"
          placeholder="Pilih Tahun Ajaran"
          :options="classroomYearsList"
        />
      </b-form-group>
      <b-form-group label="Kelas Mulai">
        <flat-pickr
          v-model="classroomContent.data.start_date"
          class="form-control"
          :config="{
            altInput: true,
            altFormat: 'j F Y',
            dateFormat: 'Y-m-d',
            minDate: 'today',
          }"
        />
      </b-form-group>

      <b-form-group label="Tipe Kelas">
        <div class="d-flex align-items-center">
          <b-form-checkbox
            v-model="isOpenClass"
            name="check-button"
            switch
            inline
            class="mr-0"
            @change="isOpenClassChange"
          />
          <span class="-mr-1 line-height-small">
            Terbuka
          </span>
        </div>
      </b-form-group>
      <b-form-group v-show="!isOpenClass" label="Kata Sandi Kelas">
        <b-form-input
          id="classroom-name"
          v-model="classroomContent.data.enrollment_key"
          placeholder="Masukan Kata Sandi Kelas"
        />
      </b-form-group>

      <b-form-group label="Tipe Clone">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="classroomContent.data.clone_material"
              name="check-button"
              switch
              inline
              class="mr-0"
            />
            <span class="-mr-1 line-height-small">
              Materi
            </span>
          </div>
          <div class="ml-1 d-flex align-items-center">
            <b-form-checkbox
              v-model="classroomContent.data.clone_task"
              name="check-button"
              switch
              inline
              class="mr-0"
            />
            <span class="-mr-1 line-height-small">
              Tugas
            </span>
          </div>
        </div>
      </b-form-group>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="w-100"
        variant="primary"
        @click="handleSubmit"
      >
        <i v-show="!isLoading" class="font-medium-2 ti ti-circle-plus" />
        <b-spinner v-show="isLoading" small variant="light" />
        Clone Kelas
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'

import client from '@/libs/http/axios-config'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText, pascalCase } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,

    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      isLoading: false,
      classroomId: this.$router.currentRoute.params.id,
      classroomContent: {
        data: {
          title: '',
          description: '',
          logo: 'ti-book',
          schedule: 'Senin',
          enrollment_key: '',
          temporary_enrollment_key: '',
          school_id: '',
          image: '',
          start_date: '',
          school_year_id: null,
          clone_material: true,
          clone_task: true,
        },
      },

      isOpenClass: false,
      schedules: [
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu',
        'Minggu',
      ],
      classroomYearsList: [],
    }
  },

  async mounted() {
    this.classroomContent.data.start_date = new Date()
      .toISOString()
      .slice(0, 10)
    client.get('/utils/school-years').then((res) => {
      this.classroomYearsList = res.data.data.map((item) => {
        return {
          title: `${this.pascalCase(item.period)} - ${this.pascalCase(
            item.name
          )}`,
          value: item.id,
        }
      })
    })

    // * Get Classroom Data
    await client
      .get(`/school-admin/classrooms/${this.classroomId}`)
      .then((res) => {
        this.classroomContent.data.title = res.data.data.name
        this.classroomContent.data.logo = res.data.data.logo
        this.classroomContent.data.enrollment_key = res.data.data.enrollment_key
        this.classroomContent.data.image = res.data.data.image
        this.classroomContent.data.school_id = res.data.data.school_id
        this.classroomContent.data.schedule = res.data.data.schedule
        this.classroomContent.data.school_year_id = this.classroomYearsList.find(
          (year) => year.value === res.data.data.school_year_id
        )

        this.classroomContent.data.description = res.data.data.description
        // this.classroomContent.data.start_date = res.data.data.start_date
        this.classroomContent.data.temporary_enrollment_key =
          res.data.data.enrollment_key
        if (res.data.data.enrollment_key === null) {
          this.isOpenClass = true
        }
      })

    this.classroomContent.data.image = await this.urlToObject(
      this.classroomContent.data.image
    )
  },

  methods: {
    pascalCase,
    generateRandomString(length) {
      let result = ''
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    isOpenClassChange() {
      if (this.isOpenClass) {
        this.content.data.enrollment_key = null
      } else {
        if (this.temporaryEnrollKey === 'null') {
          this.content.data.enrollment_key = this.generateRandomString(6)
        } else {
          this.content.data.enrollment_key = this.content.data.temporaryEnrollKey
        }
      }
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    formValidation() {
      this.isLoading = true
      let isValid = true

      // add validation here

      return isValid
    },
    async handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.formValidation()) {
        // wrap into form data
        const data = new FormData()
        data.append('name', this.classroomContent.data.title)
        data.append('description', this.classroomContent.data.description)
        data.append('logo', this.classroomContent.data.logo)
        data.append('schedule', this.classroomContent.data.schedule)
        data.append('enrollment_key', this.classroomContent.data.enrollment_key)
        data.append('school_id', this.classroomContent.data.school_id)
        data.append('image', this.classroomContent.data.image)
        data.append('cover', this.classroomContent.data.image)
        data.append('start_date', this.classroomContent.data.start_date)
        data.append(
          'school_year_id.id',
          this.classroomContent.data.school_year_id.value
        )
        data.append('clone_material', this.classroomContent.data.clone_material)
        data.append('clone_task', this.classroomContent.data.clone_task)

        // create classroom content
        await client
          .post(`/teachers/classrooms/${this.classroomId}/copy`, data)
          .then((response) => {
            // set loading
            this.isLoading = false

            // close modal
            this.$bvModal.hide('classroom-clone-modal')

            // show success toast
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Berhasil Clone Kelas'
            )

            // emit refresh content event
            this.$emit('refresh-content')
          })
          .catch((error) => {
            console.error(error)

            this.isLoading = false
            this.showToast('danger', 'XIcon', 'Gagal', 'Kelas gagal diclone')
          })
      }
    },
    async urlToObject(image) {
      const response = await fetch(image)
      // here image is url/location of image
      const blob = await response.blob()
      const file = new File([blob], 'image.jpg', { type: blob.type })
      return file
    },
  },
}
</script>

<style lang="scss">
#classroom-clone-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}

.pointer {
  cursor: pointer;
}
</style>
