<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-settings small-gap-right" />
            <h4 class="text-primary mb-0">Pengaturan Kelas</h4>
          </div>

          <!-- Classroom Configuration -->
          <div class="d-flex pb-2">
            <b-avatar
              :src="blobImage !== '' ? blobImage : classroomContent.image"
              :text="avatarText('Kelas')"
              :variant="`light-primary`"
              size="104px"
              rounded
            />
            <div class="ml-1 d-flex flex-column justify-content-center">
              <div class="d-flex mb-1">
                <input
                  accept="image/png, image/jpg, image/jpeg"
                  ref="file"
                  type="file"
                  style="display: none"
                  @change="onFileChange"
                />
                <b-button
                  variant="outline-primary small-gap-right"
                  @click="$refs.file.click()"
                >
                  Upload Foto Baru
                </b-button>
              </div>
              <span>Upload foto kelas format jpg/png maks. 800kb</span>
            </div>
          </div>

          <div class="mt-2">
            <span class="mb-1">
              {{
                classroomContent.enrollment_key
                  ? 'Kelas ini bersifat privat'
                  : 'Kelas ini bersifat publik (tanpa kata sandi)'
              }}
            </span>
            <b-row class="mt-1">
              <!-- Nama Lengkap -->
              <b-col class="mb-1">
                <label class="d-block">Kata Sandi Kelas</label>
                <b-form-input
                  v-model="classroomContent.enrollment_key"
                  placeholder="Masukkan Nama Lengkap"
                  readonly
                />
              </b-col>
            </b-row>
          </div>

          <div>
            <b-button variant="primary" class="float-right" @click="submitForm">
              <div class="d-flex align-items-center ">
                <b-spinner
                  v-show="isLoading"
                  class="mr-1"
                  variant="light"
                  small
                />
                Simpan
              </div>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BSpinner,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import client from '@/libs/http/axios-config'
import { avatarText, pascalCase } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BSpinner,
    BFormInput,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    classroomContent: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // Initial Data
      classroomId: this.$router.currentRoute.params.id,

      // Form Data
      isLoading: false,
      isOpenClass: false,
      blobImage: '',
      formData: {
        classroomLogo: '',
      },
    }
  },
  computed: {},
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.formData.classroomLogo = file
      this.blobImage = URL.createObjectURL(file)
    },
    pascalCase,
    avatarText,
    async submitForm() {
      this.isLoading = true

      const data = new FormData()
      data.append('name', this.classroomContent.name)
      data.append('description', this.classroomContent.description)
      data.append('logo', this.classroomContent.logo)
      data.append('image', this.formData.classroomLogo)
      data.append('cover', this.formData.classroomLogo)
      data.append('schedule', this.classroomContent.schedule)
      data.append('user_id', this.classroomContent.teacher.id)
      data.append('start_date', this.classroomContent.start_date)
      data.append('_method', 'PUT')

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // update classroom
      await client
        .post(`/classrooms/${this.classroomId}`, data, config)
        .then((response) => {
          // stop loading
          this.isLoading = false

          // show success toast
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Foto Kelas berhasil diedit'
          )

          // redirect to classroom detail
          this.$router.go()
        })
        .catch((error) => {
          this.isLoading = false
          console.error(error)
          // show success toast
          this.showToast('error', 'XIcon', 'Gagal', error.response.data.message)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>
