<template>
  <div>
    <div v-if="!isLoading">
      <classroom-header
        :header-data="headerData"
        :classroom-content="classroomContent"
        @isEditModeOn="isEditModeOn"
      />

      <!-- Navigation Tab -->
      <div>
        <b-row class="mb-2">
          <b-col lg="12">
            <b-tabs pills class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
              <template #tabs-start>
                <b-nav-item
                  v-for="(navtab, index) in navTabs"
                  :key="index"
                  :active="currentTab === index"
                  class="font-weight-bold small-gap-right"
                  @click="currentTab = index"
                >
                  <i class="ti" :class="navtab.icon" />
                  <span class="d-none d-md-block">{{ navtab.title }}</span>
                </b-nav-item>
              </template>
            </b-tabs>
          </b-col>
        </b-row>
      </div>

      <!-- Content Section-->
      <div v-if="currentTab === 0" class="material-section mt-2">
        <classroom-content
          :classroom-content-data="classroomContent"
          :is-edit-mode="editMode"
          :announcement-data="classroomContent.announcements"
          @update-content="refreshContentData"
        />
      </div>

      <!-- Student Section -->
      <div v-if="currentTab === 1" class="student-section mt-2">
        <classroom-student />
      </div>

      <!-- Presence Section -->
      <div v-if="currentTab === 2" class="presence-section mt-2">
        <classroom-presence :content-list="classroomContent.class_contents" />
      </div>

      <!-- Grade Section-->
      <div v-if="currentTab === 3" class="grade-section mt-2">
        <classroom-grade :content-list="classroomContent.class_contents" />
      </div>
      <!-- Class Journal Section -->
      <div v-if="currentTab === 4" class="class-journal-section mt-2">
        <classroom-journal :classroom-content-data="classroomContent" />
      </div>
      <!-- Setting Section -->
      <div v-show="currentTab === 5" class="setting-section mt-2">
        <classroom-setting :classroomContent="classroomContent" />
      </div>
    </div>

    <!-- Loading Material -->
    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Kelas
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// *Components
import { BTabs, BNavItem, BRow, BCol, BSpinner } from 'bootstrap-vue'
import ClassroomHeader from './component/ClassroomHeader.vue'
import ClassroomContent from './section/ClassroomContent.vue'
import ClassroomStudent from './section/ClassroomStudent.vue'
import ClassroomPresence from './section/ClassroomPresence.vue'
import ClassroomGrade from './section/ClassroomGrade.vue'
import ClassroomJournal from './section/ClassroomJournal.vue'
import ClassroomSetting from './section/ClassroomSetting.vue'

// *Utils
import client from '@/libs/http/axios-config'

export default {
  components: {
    ClassroomHeader,
    ClassroomContent,
    ClassroomStudent,
    ClassroomPresence,
    ClassroomGrade,
    ClassroomJournal,
    ClassroomSetting,
    BTabs,
    BNavItem,
    BRow,
    BCol,
    BSpinner,
  },
  data() {
    return {
      isLoading: true,
      editMode: false,
      isAnnouncementExist: false,

      currentTab: 0,
      classroomId: this.$router.currentRoute.params.id,
      classroomContent: {},
      headerData: {
        classroomTitle: 'class_title',
        classroomLogo: '/img/avatar-s-2.da5e73c7.jpg',
        teacherName: 'teacher_name',
        classroomCoverImg: 'https://picsum.photos/id/10/2500/1667',
      },
      navTabs: [
        { icon: 'ti-book', title: 'Materi' },
        { icon: 'ti-users', title: 'Siswa' },
        { icon: 'ti-list-check', title: 'Presensi' },
        { icon: 'ti-star', title: 'Nilai' },
        { icon: 'ti-file-text', title: 'Jurnal Kelas' },
        { icon: 'ti-settings', title: 'Pengaturan' },
      ],
    }
  },
  async mounted() {
    await client
      .get(`/teachers/classrooms/${this.classroomId}`)
      .then((response) => {
        const classroom = response.data.data

        this.headerData.classroomTitle = classroom.name
        this.headerData.classroomIcon = classroom.logo
        this.headerData.classroomLogo = classroom.image
        this.headerData.classroomCover = classroom.cover
        this.headerData.teacherName = classroom.teacher.name

        // sort content by week
        classroom.class_contents.sort((a, b) => {
          if (a.week > b.week) {
            return 1
          }
          if (a.week < b.week) {
            return -1
          }
          return 0
        })

        classroom.class_contents.forEach((content) => {
          content.tasks.map((task) => {
            if (task.is_exam === 1) {
              content.hasExam = true
            }
          })
        })

        this.classroomContent = classroom
      })
      .catch((error) => {
        console.error('get classroom data', error)
      })

    await client
      .get(`/teachers/classrooms/${this.classroomId}/announcements`)
      .then((response) => {
        const announcements = response.data.data

        this.classroomContent.announcements = announcements

        this.isLoading = false
      })
      .catch((error) => {
        console.error('announcement', error)
      })
  },
  methods: {
    isEditModeOn(value) {
      this.editMode = value
    },

    async refreshContentData() {
      console.log('refresh content data')

      await client
        .get(`/teachers/classrooms/${this.classroomId}/announcements`)
        .then((response) => {
          const announcements = response.data.data

          this.classroomContent.announcements = announcements
        })
        .catch((error) => {
          console.error('announcement', error)
        })

      await client
        .get(`/teachers/classrooms/${this.classroomId}`)
        .then((response) => {
          const classroom = response.data.data

          // sort content by week
          classroom.class_contents.sort((a, b) => {
            if (a.week > b.week) {
              return 1
            }
            if (a.week < b.week) {
              return -1
            }
            return 0
          })

          classroom.class_contents.forEach((content) => {
            content.tasks.map((task) => {
              if (task.is_exam === 1) {
                content.hasExam = true
              }
            })
          })

          this.classroomContent.class_contents = classroom.class_contents
        })
        .catch((error) => {
          console.error('get classroom data', error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/vue/libs/vue-good-table.scss';

.card-img-overlay {
  padding: 0 !important;
}

.news-slide-content {
  border-radius: 0.428rem !important;

  img {
    height: 350px;
    border-radius: 0.428rem !important;
    object-fit: cover;
  }
}
</style>
