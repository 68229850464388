<template>
  <!-- Content Add Modal -->
  <b-modal centered id="announcement-add-modal" title="Pengumuman">
    <div>
      <b-form-input v-model="classroomId" hidden />
      <b-form-group label="Judul Pengumuman">
        <b-form-input v-model="classroomContent.data.title" required />
      </b-form-group>
      <b-form-group label="Deskripsi Pengumuman">
        <b-form-textarea
          v-model="classroomContent.data.description"
          required
          rows="3"
          no-resize
        />
      </b-form-group>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="w-100"
        variant="primary"
        @click="handleSubmit"
      >
        <i v-show="!isLoading" class="font-medium-2 ti ti-circle-plus" />
        <b-spinner v-show="isLoading" small variant="light" />
        Buat Pengumuman
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'

import client from '@/libs/http/axios-config'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    announcementData: {
      type: Object,
      default: () => {
        return {
          title: '',
          description: '',
        }
      },
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      classroomId: this.$router.currentRoute.params.id,
      classroomContent: {
        data: {
          title: '',
          description: '',
        },
        errors: {
          title: { state: null, message: '' },
          description: { state: null, message: '' },
        },
      },
    }
  },
  mounted() {
    console.log(this.isUpdate)
    if (this.isUpdate) {
      this.classroomContent.data.title = this.announcementData.title
      this.classroomContent.data.description = this.announcementData.description
    }
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    resetModal() {
      this.classroomContent.data.title = ''
      this.classroomContent.data.description = ''
    },
    formValidation() {
      this.isLoading = true
      let isValid = true

      return isValid
    },
    async handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.formValidation()) {
        // wrap into form data
        const data = new FormData()
        data.append('title', this.classroomContent.data.title)
        data.append('description', this.classroomContent.data.description)

        if (!this.isUpdate) {
          // create classroom content
          await client
            .post(
              `/teachers/classrooms/${this.classroomId}/announcements`,
              data
            )
            .then((response) => {
              // set loading
              this.isLoading = false

              // reset modal content value
              this.resetModal()

              // close modal
              this.$bvModal.hide('announcement-add-modal')

              // show success toast
              this.showToast(
                'success',
                'CheckIcon',
                'Berhasil',
                'Pengumuman berhasil dibuat'
              )

              // emit refresh content event
              this.$emit('refresh-content')
            })
            .catch((error) => {
              console.error(error)

              this.isLoading = false
              this.showToast(
                'danger',
                'XIcon',
                'Gagal',
                'Pengumuman gagal dibuat'
              )
            })
        } else {
          data.append('_method', 'PUT')

          await client
            .post(
              `teachers/classrooms/${this.classroomId}/announcements/${this.announcementData.id}`,
              data
            )
            .then((response) => {
              this.isLoading = false

              // close modal
              this.$bvModal.hide('announcement-add-modal')

              // show success toast
              this.showToast(
                'success',
                'CheckIcon',
                'Berhasil',
                'Pengumuman berhasil diubah'
              )

              // emit refresh content event
              this.$emit('refresh-content')
            })
        }
      }
    },
  },
}
</script>

<style lang="scss">
#announcement-add-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
